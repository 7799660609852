
import { API_BASE } from '../constants';

async function getAllConcerts(options) {

    const url = `${API_BASE}/concert/allWithDetail` 
    const data = await fetch(url, {

        method: 'POST',
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}

async function getConcertYears() {
    let response
    const url = `${API_BASE}/concert/getConcertYears`;
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    if (data.status !== 200) {
        response = { message: "ERROR", status: data.status }
    } else {
        response = await data.json();
    }
    return response;
}

async function getConcertDetails(id) {
    const url = `${API_BASE}/concert/${id}`
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;
}


async function saveConcert(concertData) {
    const url = `${API_BASE}/concert/saveConcert`
    const resData = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(concertData),
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await resData.json();
    return response;
}

const getConcertTypeOptions = () => {
    let options = []

    options.push({
        label: "Rock & Roll",
        value: 3,
        roles: ['admin', 'user']
    })

    options.push({
        label: "Jukebox",
        value: 2,
        roles: ['admin']
    })

    options.push({
        label: "Woodmanoteka",
        value: 1,
        roles: ['admin']
    })

    options.push({
        label: "Nanovo",
        value: 4,
        roles: ['admin']
    })

    const validOptions = options.filter((element) => element.roles.includes(sessionStorage.getItem("userRole")))
    const result = (validOptions.length > 0 ? validOptions : { label: "Rock & Roll", value: 3, roles: ['admin', 'user'] })
    return result
}

export default {
    getAllConcerts,
    getConcertYears,
    getConcertDetails,
    saveConcert,
    getConcertTypeOptions
};
