import React, { Fragment } from "react";
import Breadcrums from "./Breadcrums";
import 'react-notifications/lib/notifications.css';
import userService from "../services/user.service";

const Layout = (props) => {

  const logout = () => {
    console.log('XX')
    sessionStorage.setItem("accessToken", false);
    window.location.href = "/"
  }

  return (

    <Fragment>
      <div id="wrapper">

        <nav className="navbar-default navbar-static-side" role="navigation">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
              <li className="nav-header">
                <div className="dropdown profile-element">
                  <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                    <span className="block m-t-xs font-bold">{sessionStorage.getItem("username")}</span>
                    {/* <span className="text-muted text-xs block">menu <b className="caret"></b></span> */}
                  </a>
                  <ul className="dropdown-menu animated fadeInRight m-t-xs">
                    <li><a className="dropdown-item" href="login.html" onClick={logout}>Logout</a></li>
                  </ul>
                </div>
                <div className="logo-element">
                  Woodman Producton
                </div>
              </li>
              <li className="active">
                <a href="/"><i className="fa fa-th-large"></i> <span className="nav-label">Koncerty</span></a>
              </li>
              {userService.isAdmin() && <li>
                <a href="/users"><i className="fa fa-th-large"></i> <span className="nav-label">Uživatelé</span></a>
              </li>}
            </ul>

          </div>
        </nav>

        <div id="page-wrapper" className="gray-bg">
          <div className="row border-bottom">
            <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ "marginBottom": "0" }}>
              <div className="navbar-header">
                <img src="https://www.woodmanproduction.cz/img/logo.png" style={{ "marginLeft": "10px", "marginTop": "10px", "marginBottom":"10px" }}></img>
                {/* <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i
                  className="fa fa-bars"></i> </a> */}
                {/* <form role="search" className="navbar-form-custom" method="post" action="#">
                  <div className="form-group">
                    <input type="text" placeholder="Search for something..." className="form-control"
                      name="top-search" id="top-search" />
                  </div>
                </form> */}
              </div>
              <ul className="nav navbar-top-links navbar-right">
                <li>
                  <a href="#" onClick={logout}>
                    <i className="fa fa-sign-out"></i> Odhlásit
                  </a>
                </li>
              </ul>

            </nav>
          </div>
          <Breadcrums />
          {props.children}
          <div className="footer">
            <div className="pull-right">
            </div>
            <div>
              <strong>Copyright</strong> Woodman Production &copy; 2004-2023
            </div>
          </div>

        </div>
      </div>

      <script src="/js/jquery-3.1.1.min.js"></script>
      <script src="/js/popper.min.js"></script>
      <script src="/js/bootstrap.min.js"></script>
      <script src="/js/plugins/metisMenu/jquery.metisMenu.js"></script>
      <script src="/js/plugins/slimscroll/jquery.slimscroll.min.js"></script>

      <script src="/js/inspinia.js"></script>
      <script src="/js/plugins/pace/pace.min.js"></script>




    </Fragment>
  );
};

export default Layout;
