import { configureStore }  from '@reduxjs/toolkit' //from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import cartSlice from './cart-slice';


const reducers = combineReducers({
  cart: cartSlice.reducer,
});

// const store = configureStore({
//   reducer: { cart: cartSlice.reducer },
// });

const persistConfig = {
  key: 'root',
  storage,
};

const preloadedState = {

}

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
  preloadedState
});


export default store;
