import React from "react";
import { Redirect, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import userService from "../services/user.service";

const ProtectedRoute = ({ path, exact, children }) => {
    return userService.verifyToken() ? (
        <Layout>
            <Route path={path} exact={exact}>
                {children}
            </Route>
        </Layout>
    ) : (
        <Redirect to="/login" />
    );
};

export default ProtectedRoute;