import { API_BASE } from '../constants';

async function auth(username, password) {
    const url = `${API_BASE}/api/auth/signin`


    const data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ "username": username, "password": password }),
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json",
        }
    });


    const response = await data.json();
    return response
}

export default {
    auth
}