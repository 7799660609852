import { createSlice } from '@reduxjs/toolkit';



const setHorniNapisPage = (pageNo, napisCount) => {
  const napisShift = pageNo * -142 * 4;
  const horniNapisWidth = (napisCount + 1) * 142;
  const horniNapisContentStyle = { width: horniNapisWidth + 'px', left: napisShift + 'px' }
  return horniNapisContentStyle
}

const initialState = () => ({
  isLoggedIn: false
})

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState(),
  reducers: {
    isLogged(state, action) {
      state.isLoggedIn = true;
    },
    logOut(state, action) {
      state.isLoggedIn = false;
    },
    setToken(state, action) {
      state.token = action.payload.token;
    },

  }
});

export const cartActions = cartSlice.actions;

export default cartSlice;
