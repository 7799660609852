import React from 'react';


const Breadcrumbs = (props) => {

    return (
        <React.Fragment>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    {/* <h2>Woodman Production</h2> */}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://koncerty-woodman.woodman.cz/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="https://koncerty-woodman.woodman.cz/">Koncerty</a>
                        </li>
                    </ol>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Breadcrumbs;