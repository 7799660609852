import React, { Fragment, useEffect, useState, useCallback } from "react";

import Select from 'react-select'
import chroma from 'chroma-js';

import concertService from '../services/concert.service'

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import date from 'date-and-time';

const ConcertInfoTab = (props) => {

    const isNew = (props?.isNew) ? props.isNew : false


    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (

        <input
            type="text"
            className="form-control"
            id="datum"
            name="datum"
            value={value}
            onClick={onClick}
            ref={ref}
            required
            readOnly
        />

    ));

    registerLocale('cs', cs)

    const [startDate, setStartDate] = useState(new Date());

    const isActiveTab = props.isActiveTab

    const checkActiveTab = () => {
        return (isActiveTab ? " active" : "")
    }


    const createNotification = (type) => {
        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('Success message', 'Title here');
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
            }
        };
    };

    const [optionsData, setOptionsData] = useState({})
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)




    const [formData, setFormData] = useState({})

    const rgbToHex = (rgb) => {
        let hex = Number(rgb).toString(16);

        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex === "NaN" ? "00" : hex;
    };

    const fullColorHex = (rgb) => {
        var red = rgbToHex(rgb[0]);
        var green = rgbToHex(rgb[1]);
        var blue = rgbToHex(rgb[2]);
        return red + green + blue;
    };

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.3).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: color.alpha(.5).css(),
            };
        },
        multiValueLabel: (styles) => {

            return {
                ...styles,
                color: "#000000",
            }
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };


    const fetchConcert = useCallback(async () => {

        const concertData = await concertService.getConcertDetails(props.id)
        const splitDate = concertData.datum.split('-')
        const splitTime = concertData.cas ? concertData.cas.split(':') : ["17", "00"]
        const concertDateAndTime = new Date(splitDate[0], splitDate[1] - 1, splitDate[2], splitTime[0], splitTime[1])
        setStartDate(concertDateAndTime)
        const initialFormData = { ...concertData, urcite: translateYesToOne(concertData.urcite) }
        setFormData(initialFormData)
        return concertData
    }, [props.id]);

    const fetchData = useCallback(async () => {
        const concertData = await fetchConcert()
        const optionsData = {
            concertTypeChosen: concertService.getConcertTypeOptions().find((element) => element.value == concertData.typ_akce),
            concertTypeOptions: concertService.getConcertTypeOptions()
        }
        setOptionsData(optionsData)
    }, [fetchConcert])


    useEffect(() => {
        if (!isNew) {
            fetchData();
        } else {
            const optionsData = {
                concertTypeChosen: [],
                concertTypeOptions: concertService.getConcertTypeOptions()
            }
            setOptionsData(optionsData)

            const newStartDate = new Date()
            setStartDate(newStartDate)
            setFormData({ ...formData, datum: date.format(newStartDate, 'YYYY-MM-DD'), cas: date.format(newStartDate, 'HH:mm') })
        }
    }, [fetchData]);


    const inputChangeHandler = (event) => {
        const id = event.target.id.split("#")[0]
        setFormData({ ...formData, [id]: event.target.value })
    }

    const dateInputChangeHandler = (event) => {
        console.log(event)
        setStartDate(event)
        // date.format(now,'YYYY/MM/DD HH:mm:ss');
        setFormData({ ...formData, datum: date.format(event, 'YYYY-MM-DD'), cas: date.format(event, 'HH:mm') })
    }


    const buttonChangeHandler = (event) => {
        const id = event.target.id.split("#")[0]
        const value = formData[id]
        let newValue = !value
        if (value === 0)
            newValue = 1
        if (value === 1)
            newValue = 0

        console.log({ ...formData, [id]: newValue })
        setFormData({ ...formData, [id]: newValue })
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsSaveInProgress(true)
        const submittedFormData = { ...formData, isNew: isNew, urcite: translateOneToYes(formData.urcite) }
        submittedFormData.typ_akce = optionsData.concertTypeChosen.value
        console.log(submittedFormData)
        const saveReply = await concertService.saveConcert(submittedFormData)
        console.log(saveReply.body)
        const visibility = props.visibility
        props.setVisibilityChange(!visibility)
        props.setVisibility(!visibility)
        createNotification("info")
        setIsSaveInProgress(false)
        if (saveReply.message === 'OK' && isNew)
            window.location.href = "/concert/" + saveReply.id_koncertu;

    }

    const onConcertTypeChangeHandler = (event) => {
        setOptionsData({ ...optionsData, concertTypeChosen: event })
    }

    const testData = {
        "id_koncertu": 1297,
        "mesto": "Trutnov - kemp Dolce",
        "datum": "2023-05-27",
        "cas": null,
        "poznamka": "100%, sraz VW Transporterů",
        "komentar": "",
        "hodnoceni": "",
        "chodnoceni": 0,
        "zobrazit": 1,
        "zaridil": "marcel",
        "cena": 100000,
        "urcite": "ano",
        "detaily": "Michaela Šviková\r\n \r\nEvent manager \r\n \r\nritual communication s.r.o.\r\nPalackého 75, 29001 Poděbrady\r\nEmail: misa@ritualni.cz\r\nDirect: 736 759 576",
        "agentura_id": 0,
        "aparat": 0,
        "svetla": 0,
        "dj": 0,
        "podium": 0,
        "smlouva": 0,
        "zaloha": 70000,
        "facastka": 0,
        "zpplatby": null,
        "zruseno": 0,
        "agentura_nazev": "wP",
        "poradi": null,
        "nadpis": "sraz VW Transporterů",
        "ukazat_detail": 0,
        "detail_url": "",
        "typ_akce": 3
    }


    const translateYesToOne = (input) => {
        const result = (input === 'ano' ? 1 : 0)
        return result
    }

    const translateOneToYes = (input) => {
        const result = (input === 1 ? 'ano' : 'ne')
        return result
    }

    return (
        <Fragment>
            <div id="tab-1" className={`tab-pane ${checkActiveTab()}`}>
                <form onSubmit={handleSubmit} id="tab1form">
                    <input type="hidden" id="id_koncertu" name="id_koncertu" defaultValue={formData?.id_koncertu} />
                    <div className="panel-body">
                        <fieldset>
                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label text-danger"><strong>Město:</strong></label>
                                <div className="col-sm-2"><input type="text" className="form-control" id="mesto" name="mesto" defaultValue={formData?.mesto} onChange={inputChangeHandler} required /></div>
                                <label className="col-sm-2 col-form-label text-danger"><strong>Datum a čas koncertu:</strong></label>
                                <div className="col-1.5">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={dateInputChangeHandler}
                                        showTimeSelect
                                        dateFormat="Pp"
                                        locale="cs"
                                        todayButton="Dnes"
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label text-danger"><strong>Nadpis:</strong></label>
                                <div className="col-sm-2"><input type="text" className="form-control" id="nadpis" name="nadpis" defaultValue={formData?.nadpis} onChange={inputChangeHandler} required /></div>
                                <label className="col-sm-1 col-form-label text-danger"><strong>Typ akce:</strong></label>
                                <div className="col-sm-2">
                                    <Select
                                        closeMenuOnSelect={true}
                                        defaultOptions={true}
                                        value={optionsData?.concertTypeChosen}
                                        name="typ_akce"
                                        id="typ_akce"
                                        options={optionsData?.concertTypeOptions}
                                        onChange={onConcertTypeChangeHandler}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label">Detaily:</label>
                                <div className="col-sm-3">
                                    <textarea className="note-editable card-block border rounded-lg form-control " aria-multiline="true" spellCheck="false" id="detaily" name="detaily" defaultValue={formData?.detaily} rows="8" onChange={inputChangeHandler} />
                                </div>
                                <label className="col-sm-1 col-form-label text-danger"><strong>Poznámka:</strong></label>
                                <div className="col-sm-3">
                                    <textarea className="note-editable card-block border rounded-lg form-control " aria-multiline="true" spellCheck="false" id="poznamka" name="poznamka" defaultValue={formData?.poznamka} rows="8" onChange={inputChangeHandler} required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label">Cena:</label>
                                <div className="col-sm-1"><input type="text" className="form-control" placeholder="0" id="cena" name="cena" defaultValue={formData?.cena} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-1 col-form-label">Záloha:</label>
                                <div className="col-sm-1"><input type="text" className="form-control" placeholder="0" id="zaloha" name="zaloha" defaultValue={formData?.zaloha} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-1 col-form-label">Faktura:</label>
                                <div className="col-sm-1"><input type="text" className="form-control" placeholder="0" id="facastka" name="facastka" defaultValue={formData?.facastka} onChange={inputChangeHandler} /></div>

                            </div>

                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label">Zobrazit na webu:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.zobrazit === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="zobrazit#button" onClick={buttonChangeHandler}>
                                        <i id="zobrazit#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Určitě:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.urcite === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="urcite#button" onClick={buttonChangeHandler}>
                                        <i id="urcite#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Aparát:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.aparat === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="aparat#button" onClick={buttonChangeHandler}>
                                        <i id="aparat#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Světla:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.svetla === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="svetla#button" onClick={buttonChangeHandler}>
                                        <i id="svetla#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="form-group row">

                                <label className="col-sm-1 col-form-label">DJ:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.dj === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="dj#button" onClick={buttonChangeHandler}>
                                        <i id="dj#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Pódium:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.podium === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="podium#button" onClick={buttonChangeHandler}>
                                        <i id="podium#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Smlouva:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.smlouva === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="smlouva#button" onClick={buttonChangeHandler}>
                                        <i id="smlouva#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Zrušeno:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.zruseno === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="zruseno#button" onClick={buttonChangeHandler}>
                                        <i id="zruseno#i" className="fa fa-check"></i>
                                    </button>
                                </div>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label">Zobrazit odkaz na akci:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.ukazat_detail === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="ukazat_detail#button" onClick={buttonChangeHandler}>
                                        <i id="ukazat_detail#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Odkaz na akci:</label>
                                <div className="col-sm-5"><input type="text" className="form-control" id="detail_url" name="detail_url" defaultValue={formData?.detail_url} onChange={inputChangeHandler} /></div>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-1 col-form-label">Veřejná:</label>
                                <div className="col-sm-1">
                                    <button className={`btn ${formData.verejna === 1 ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="verejna#button" onClick={buttonChangeHandler}>
                                        <i id="verejna#i" className="fa fa-check"></i>
                                    </button>
                                </div>
                                <label className="col-sm-1 col-form-label">Email na organizátora:</label>
                                <div className="col-sm-5"><input type="text" className="form-control" id="email" name="email" defaultValue={formData?.email} onChange={inputChangeHandler} /></div>

                            </div>
                            <div className="form-group row">
                                <div className="col">
                                    <table align="right" >
                                        <tbody>
                                            <tr>
                                                <td> {isSaveInProgress && <div className="sk-spinner sk-spinner-circle">
                                                    <div className="sk-circle1 sk-circle">s</div>
                                                    <div className="sk-circle2 sk-circle"></div>
                                                    <div className="sk-circle3 sk-circle"></div>
                                                    <div className="sk-circle4 sk-circle"></div>
                                                    <div className="sk-circle5 sk-circle"></div>
                                                    <div className="sk-circle6 sk-circle"></div>
                                                    <div className="sk-circle7 sk-circle"></div>
                                                    <div className="sk-circle8 sk-circle"></div>
                                                    <div className="sk-circle9 sk-circle"></div>
                                                    <div className="sk-circle10 sk-circle"></div>
                                                    <div className="sk-circle11 sk-circle"></div>
                                                    <div className="sk-circle12 sk-circle"></div>
                                                </div>

                                                }</td>
                                                <td>
                                                    <button className="btn btn-warning" onClick={event => window.location.href = 'https://koncerty-woodman.woodman.cz/'}>
                                                        <i className="fa fa-arrow-left" ></i>
                                                        &nbsp;Zpět</button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary" type="submit" disabled={isSaveInProgress}>
                                                        <i className="fa fa-floppy-o" ></i>
                                                        &nbsp;Uložit</button>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </form>
            </div >

        </Fragment >
    )
};

export default ConcertInfoTab;
