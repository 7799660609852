import React, { useEffect, useState, useCallback, Fragment } from 'react';
import authService from '../../services/auth.service';
import Select, { components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props } from "react-select";
import userService from '../../services/user.service';

const NewUser = (props) => {

    const [editingPassword, setEditingPassword] = useState(false)
    const [editingRole, setEditingRole] = useState(false)
    const [username, setUsername] = useState("")
    const [userData, setUserData] = useState({ role: 'user' })
    const [currentPassword, setCurrentPassword] = useState()

    const setIsNewUser = props.setIsNewUser



    const onUsernameChangeHandler = (event) => {

        setUserData({ ...userData, username: event?.target?.value })
    };

    const onUserRoleChangeHandler = (event) => {
        setUserData({ ...userData, role: event.value })

        setEditingRole(false)
    }

    const saveUser = async (event) => {
        event.preventDefault();
        const result = await userService.createUser(userData)
        console.log(result)
        if (result?.message === 'OK') {
            window.location.href = "/users";
        }
    };



    return (
        <Fragment>
            <tr className="footable-even" key={"user-item-new"}>
                <td className="footable-visible footable-first-column">
                    <span className="footable-toggle"></span>

                </td>
                <td className="footable-visible"><input onChange={onUsernameChangeHandler} required /></td>
                <td className="footable-visible" >
                    <Select
                        closeMenuOnSelect={true}
                        name="role"
                        id="role"
                        value={userService.getUserRoleOptions().find((element) => element.value == userData.role)}
                        options={userService.getUserRoleOptions()}
                        onChange={onUserRoleChangeHandler}
                    ></Select>
                </td>
                <td className="footable-visible"></td>

                <td className="text-right footable-visible footable-last-column">
                    <div className="btn-group">
                        <button
                            className="btn-white btn btn-xs"
                            id={`new-user-button`}
                            onClick={saveUser}
                        >
                            Uložit
                        </button>
                        <button className="btn-white btn btn-xs" onClick={() => { setIsNewUser(false) }}>Zrušit</button>
                    </div>
                </td>
            </tr>
        </Fragment>
    )
}

export default NewUser