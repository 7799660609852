import { API_BASE } from '../constants';

async function getAll(options) {

    const url = `${API_BASE}/user`
    const data = await fetch(url, {

        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}


const getUserRoleOptions = () => {
    const roles = [
        { label: "Administrator", value: "admin" },
        { label: "Uživatel", value: "user" }
    ]
    return roles
}

async function setRole(options) {

    const url = `${API_BASE}/user/setRole`
    const data = await fetch(url, {

        method: 'POST',
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}


async function changePassword(options) {



    const url = `${API_BASE}/user/changePassword`
    const data = await fetch(url, {

        method: 'POST',
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}

async function verifyToken() {
    const url = `${API_BASE}/user/verifyToken`
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const status = data.status

    if (status !== 200) {
        return false
    } else {
        return true
    }
}

async function createUser(userData) {
    const url = `${API_BASE}/user/createUser`
    const data = await fetch(url, {

        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}

async function deleteUser(userData) {
    const url = `${API_BASE}/user/deleteUser`
    const data = await fetch(url, {

        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response;
}

function isAdmin() {
    const isAdmin = sessionStorage.getItem("userRole") === 'admin' ? true : false
    return isAdmin
}

export default {
    getAll,
    getUserRoleOptions,
    setRole,
    changePassword,
    verifyToken,
    createUser,
    deleteUser,
    isAdmin
}
