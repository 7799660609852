import React, { useEffect, useState, useCallback, Fragment } from 'react';
import authService from '../../services/auth.service';
import Select, { components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props } from "react-select";
import userService from '../../services/user.service';

const UserListItem = (props) => {

    const [editingPassword, setEditingPassword] = useState(false)
    const [editingRole, setEditingRole] = useState(false)
    const [addingUser, setAddingUser] = useState(false)
    const [userData, setUserData] = useState(props.user)
    const [currentPassword, setCurrentPassword] = useState()

    const setRecordDeleted = props.setRecordDeleted
    const recordDeleted = props.recordDeleted


    // const user = props.user

    const editPassword = (event) => {
        console.log(event);
        setEditingPassword(true)
        // const id = event.target.id.split("#")[1];
        // event.preventDefault();
        // window.location.href = "/user/" + id;
    };

    const cancelEditPassword = (event) => {
        console.log(event);
        setEditingPassword(false)
        // const id = event.target.id.split("#")[1];
        // event.preventDefault();
        // window.location.href = "/user/" + id;
    };

    const savePassword = (event) => {
        console.log(currentPassword);

        // const token = authService.auth('jaroslav', currentPassword)
        userService.changePassword({
            id: userData.id,
            password: currentPassword
        })
        setEditingPassword(false)
        // const id = event.target.id.split("#")[1];
        // event.preventDefault();
        // window.location.href = "/user/" + id;
    };


    const changeUserRole = (userId) => {
        setEditingRole(true)

    };

    const onUserRoleChangeHandler = (event) => {
        setUserData({ ...userData, role: event.value })
        const updateResponse = userService.setRole({ id: userData.id, role: event.value })
        setEditingRole(false)
    }

    const onPasswordChangeHandler = (event) => {
        setCurrentPassword(event.target.value)
    };

    const deleteUserHandler = async () => {
        const result = await userService.deleteUser(userData)
        setRecordDeleted(!recordDeleted)
    }

    return (
        <Fragment>
            <tr className="footable-even" key={"user-item-" + userData.id}>
                <td className="footable-visible footable-first-column">
                    <span className="footable-toggle"></span>
                    {userData.id}
                </td>
                <td className="footable-visible">{userData.username}</td>
                <td className="footable-visible" >
                    {!editingRole && <a onClick={() => { changeUserRole(userData.id) }}> {userService.getUserRoleOptions().find((element) => element.value == userData.role).label}</a>}
                    {editingRole && <Select
                        closeMenuOnSelect={true}
                        name="role"
                        id="role"
                        value={userService.getUserRoleOptions().find((element) => element.value == userData.role)}
                        options={userService.getUserRoleOptions()}
                        onChange={onUserRoleChangeHandler}
                    ></Select>}
                </td>
                <td className="footable-visible">{editingPassword && <Fragment>
                    <div className="input-group input-group-sm mb-3">
                        <input type="password" placeholder="heslo" id={`user-password-${userData.id}`} className="form-control" size="10" onChange={onPasswordChangeHandler} />
                    </div>
                </Fragment>}</td>

                <td className="text-right footable-visible footable-last-column">
                    <div className="btn-group">


                        {editingPassword && <Fragment>
                            <button className="btn btn-primary btn-xs" id={`edit-user-button#${userData.id}`} onClick={savePassword}>
                                <i className="fa fa-floppy-o"></i>
                                &nbsp;Uložit heslo
                            </button>
                            <button className="btn btn-danger btn-xs" id={`cancel-user-button#${userData.id}`} onClick={cancelEditPassword}>
                                <i className="fa fa-window-close"></i>
                                &nbsp;Zrušit
                            </button>
                        </Fragment>}



                        {!editingPassword && <Fragment>
                            <button
                                className="btn-white btn btn-xs"
                                id={`edit-user-button#${userData.id}`}
                                onClick={editPassword}
                            >
                                Změnit heslo
                            </button>
                            <button className="btn-white btn btn-xs" onClick={deleteUserHandler}>Smazat</button>
                        </Fragment>}





                    </div>
                </td>
            </tr>
        </Fragment>
    )
}

export default UserListItem