import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import ConcertInfoTab from "./ConcertInfoTab";

const ConcertDetail = (props) => {

  const params = useParams();
  const styleWidth20 = { width: "20%" }
  const textilId = params.id
  const isNew = props.isNew

  const [visibilityChange, setVisibilityChange] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [activeTab, setActiveTab] = useState({});
  const [isSchoolPricesVisible, setIsSchoolPricesVisible] = useState(false)
  const [isCompanyPricesVisible, setIsCompanyPricesVisible] = useState(false)


  const isActiveTab = (chosenTab) => {
    return (chosenTab == activeTab)
  }

  const getActiveTabClass = (chosenTab) => {
    return (chosenTab == activeTab ? "nav-link active" : "nav-link")
  }



  useEffect(() => {

    const loadData = async () => {
      setActiveTab('concertInfo');
    }
    loadData()
  }, [visibilityChange]);

  return (
    <Fragment>
      <div className="wrapper wrapper-content animated fadeInRight ecommerce">
        <div className="row">
          <div className="col-lg-12">
            <div className="tabs-container">
              <ul className="nav nav-tabs">
                <li>
                  <a className={`${getActiveTabClass('concertInfo')}`} data-toggle="tab" onClick={() => setActiveTab('concertInfo')} >Základní info</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="ibox">
                  {isActiveTab('concertInfo') && <ConcertInfoTab key={params.id} id={params.id} isActiveTab={isActiveTab('concertInfo')} setVisibilityChange={setVisibilityChange} visibility={visibility} setVisibility={setVisibility} isNew={isNew}></ConcertInfoTab>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default ConcertDetail;
