import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from './login/Login'
import ProtectedRoute from "./middleware/ProtectedRoute";
import ConcertList from "./concert/ConcertList";
import ConcertDetail from "./concert/ConcertDetail";
import UserList from "./components/users/UserList";
import userService from "./services/user.service";


function App() {
  return (


    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <ProtectedRoute exact path="/">
          <ConcertList />
        </ProtectedRoute>

        {userService.isAdmin && <ProtectedRoute path="/concert/new">
          <ConcertDetail isNew={true} />
        </ProtectedRoute>}

        {userService.isAdmin && <ProtectedRoute path="/concert/:id">
          <ConcertDetail />
        </ProtectedRoute>}

        {userService.isAdmin && <ProtectedRoute exact path="/users">
          <UserList />
        </ProtectedRoute>}
      </Switch>
    </Router>
    // </Layout>
  );
}

export default App;
