import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useSelector } from "react-redux";
import store from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { createRoot } from 'react-dom/client';
// import Login from './login/Login'

let persistor = persistStore(store);
const auth = require("./middleware/authJwt");
// const cart = useSelector((state) => state.cart);





const app = document.getElementById('root');

// create a root
const root = createRoot(app);

//render app to root
root.render(<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    {/* {console.log(auth.verifyToken)} */}
    <App />
    {/* {auth.verifyToken(cart.token) && <App />} */}
    {/* {!auth.verifyToken(cart.token) && <Login />} */}
  </PersistGate>
</Provider>,
  (() => {
    console.log("webpack worked");
  })());



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
