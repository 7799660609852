import React, { useEffect, useState, useCallback, Fragment } from 'react';
// import TextilListItem from './TextilListItem';
import concertService from '../../services/concert.service'
import authService from '../../services/auth.service';
// import ConcertListItem from './ConcertListItem';
import Select, { components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props } from "react-select";
import userService from '../../services/user.service';
import UserListItem from './UserListItem';
import ProgressSpinner from '../../layout/ProgressSpinner';
import NewUser from './NewUser';

const UserList = (props) => {
    const [users, setUsers] = useState([]);
    const [years, setYears] = useState([]);
    const [old, setOld] = useState(false)
    const [year, setYear] = useState((new Date().getFullYear()))
    const [optionsData, setOptionsData] = useState({})
    const [optionsDataChanged, setOptionsDataChanged] = useState(false)
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)
    const [isNewUser, setIsNewUser] = useState(false)
    const [recordDeleted, setRecordDeleted] = useState(false)

    const getYearsOptions = () => {
        const options = years.map((result, index) => ({
            label: result.year,
            value: result.year
        }))
        return options
    }



    const buttonChangeHandler = (event) => {
        setOld(!old)
        sessionStorage.setItem("showOld", !old);
        console.log(sessionStorage.getItem("showOld"))
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const fetchConcerts = useCallback(async () => {
        setIsSaveInProgress(true)

        // const token = await authService.auth('jaroslav', 'jaroslavx')
        // sessionStorage.setItem("accessToken", token.accessToken);
        const usersData = await userService.getAll()
        setUsers(usersData)

        // const yearsData = await concertService.getConcertYears()
        // setYears(yearsData)
        // const concertTypeChosen = sessionStorage.getItem("concertTypeChosen") ? sessionStorage.getItem("concertTypeChosen") : concertService.getConcertTypeOptions()
        // const concertTypeOptions = concertService.getConcertTypeOptions()
        // const optionsDataInitial = {
        //     concertTypeChosen: concertTypeChosen,
        //     concertTypeOptions: concertTypeOptions,
        //     concertTypeChange: false
        // }
        // if (isEmpty(optionsData)) {
        //     setOptionsData(optionsDataInitial)
        // }

        // const options = {
        //     "old": sessionStorage.getItem("showOld") ? sessionStorage.getItem("showOld") : false,
        //     "year": JSON.parse(sessionStorage.getItem("yearsChosen")),
        //     "eventType": JSON.parse(sessionStorage.getItem("concertTypeChosen")) ? JSON.parse(sessionStorage.getItem("concertTypeChosen")) : concertService.getConcertTypeOptions()
        // }
        // const concertData = await concertService.getAllConcerts(options)
        // setConcerts(concertData);
        setIsSaveInProgress(false)

    }, []);

    const onConcertTypeChangeHandler = (event) => {
        sessionStorage.setItem("concertTypeChosen", JSON.stringify(event));
        setOptionsDataChanged(!optionsDataChanged)
    }

    const onYearChangeHandler = (event) => {
        sessionStorage.setItem("yearsChosen", JSON.stringify(event));
        setOptionsDataChanged(!optionsDataChanged)
    }

    const newUser = (event) => {
        setIsNewUser(true)
        // event.preventDefault();
        // window.location.href = "/concert/new";
    };

    useEffect(() => {
        fetchConcerts();
    }, [fetchConcerts, old, optionsDataChanged, recordDeleted]);

    useEffect(() => {
        setOptionsDataChanged(!optionsDataChanged)
    }, []);

    return (
        <div className="wrapper wrapper-content animated fadeInRight ecommerce">


            <div className="ibox-content m-b-sm border-bottom">
                <a className="btn btn-rounded btn-block btn-primary compose-mail" onClick={newUser}>Nový uživatel</a>

            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <table className="footable table table-stripped toggle-arrow-tiny footable-loaded tablet breakpoint" data-page-size="15">
                                <thead>
                                    <tr>

                                        <th className="footable-visible footable-sortable footable-first-column">Číslo<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Uživatelské jméno<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Role<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable"><span className="footable-sort-indicator"></span></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {isSaveInProgress && <tr>
                                        <td colSpan="4">
                                            <ProgressSpinner />
                                        </td>
                                    </tr>}
                                    {isNewUser && <NewUser setIsNewUser={setIsNewUser} />}
                                    {users.length > 0 && users.map((oneUser) => (
                                        <Fragment key={"user-fragment-" + oneUser.id}>
                                            <UserListItem user={oneUser} key={oneUser.id} recordDeleted={recordDeleted} setRecordDeleted={setRecordDeleted}/>
                                        </Fragment>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4" className="footable-visible">
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default UserList