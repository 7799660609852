import React, { useEffect, useState, useCallback, Fragment } from 'react';
import concertService from '../services/concert.service'
import ConcertListItem from './ConcertListItem';
import Select from "react-select";
import ProgressSpinner from '../layout/ProgressSpinner';
import userService from '../services/user.service';

const ConcertList = (props) => {
    const [concerts, setConcerts] = useState([]);
    const [years, setYears] = useState([]);
    const [old, setOld] = useState(false)
    const [year, setYear] = useState((new Date().getFullYear()))
    const [optionsData, setOptionsData] = useState({})
    const [optionsDataChanged, setOptionsDataChanged] = useState(false)
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)

    const getYearsOptions = () => {
        const options = years.map((result, index) => ({
            label: result.year,
            value: result.year
        }))
        return options
    }



    const buttonChangeHandler = (event) => {
        setOld(!old)
        sessionStorage.setItem("showOld", !old);
        console.log(sessionStorage.getItem("showOld"))
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const fetchConcerts = useCallback(async () => {
        setIsSaveInProgress(true)

        // const token = await authService.auth('jaroslav', 'jaroslav')
        // sessionStorage.setItem("accessToken", token.accessToken);
        const yearsData = await concertService.getConcertYears()
        if (yearsData.message === 'ERROR' && yearsData.status === 401) {
            window.location.href = "/login";
        }
        setYears(yearsData)
        const concertTypeChosen = sessionStorage.getItem("concertTypeChosen") ? sessionStorage.getItem("concertTypeChosen") : concertService.getConcertTypeOptions()
        const concertTypeOptions = concertService.getConcertTypeOptions()
        const optionsDataInitial = {
            concertTypeChosen: concertTypeChosen,
            concertTypeOptions: concertTypeOptions,
            concertTypeChange: false
        }
        if (isEmpty(optionsData)) {
            setOptionsData(optionsDataInitial)
        }

        const options = {
            "old": sessionStorage.getItem("showOld") ? sessionStorage.getItem("showOld") : false,
            "year": JSON.parse(sessionStorage.getItem("yearsChosen")),
            "eventType": JSON.parse(sessionStorage.getItem("concertTypeChosen")) ? JSON.parse(sessionStorage.getItem("concertTypeChosen")) : concertService.getConcertTypeOptions()
        }
        const concertData = await concertService.getAllConcerts(options)
        setConcerts(concertData);
        setIsSaveInProgress(false)

    }, []);

    const onConcertTypeChangeHandler = (event) => {
        sessionStorage.setItem("concertTypeChosen", JSON.stringify(event));
        setOptionsDataChanged(!optionsDataChanged)
    }

    const onYearChangeHandler = (event) => {
        sessionStorage.setItem("yearsChosen", JSON.stringify(event));
        setOptionsDataChanged(!optionsDataChanged)
    }

    const newConcert = (event) => {
        event.preventDefault();
        window.location.href = "/concert/new";
    };

    useEffect(() => {
        fetchConcerts();
    }, [fetchConcerts, old, optionsDataChanged]);

    useEffect(() => {
        const thisYear = new Date().getFullYear();
        const nextYear = thisYear + 1
        const initialYear = [
            {
                label: thisYear,
                value: thisYear
            },
            {
                label: nextYear,
                value: nextYear
            }
        ]
        console.log(JSON.stringify(concertService.getConcertTypeOptions()))
        sessionStorage.setItem("concertTypeChosen", JSON.stringify(concertService.getConcertTypeOptions()));
        sessionStorage.setItem("yearsChosen", JSON.stringify(initialYear));


        setOptionsDataChanged(!optionsDataChanged)
    }, []);
    return (
        <div className="wrapper wrapper-content animated fadeInRight ecommerce">


            <div className="ibox-content m-b-sm border-bottom">
                {userService.isAdmin() && <a className="btn btn-rounded btn-block btn-primary compose-mail" onClick={newConcert}>Nový koncert</a>}
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="order_id">Rok</label>
                            <Select
                                closeMenuOnSelect={true}
                                defaultOptions={true}
                                name="year"
                                id="year"
                                isMulti
                                value={JSON.parse(sessionStorage.getItem("yearsChosen"))}
                                options={getYearsOptions()}
                                onChange={onYearChangeHandler}
                            ></Select>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="typ_akce">Typ Akce</label>
                            {console.log(sessionStorage.getItem("concertTypeChosen"))}
                            <Select
                                closeMenuOnSelect={true}
                                defaultOptions={true}
                                name="typ_akce"
                                id="typ_akce"
                                isMulti
                                value={JSON.parse((sessionStorage.getItem("concertTypeChosen") !== null ? sessionStorage.getItem("concertTypeChosen") : JSON.stringify(concertService.getConcertTypeOptions())))}
                                options={concertService.getConcertTypeOptions()}
                                onChange={onConcertTypeChangeHandler}
                            ></Select>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="order_id">Zobrazit staré akce</label>
                            <div className="col-sm-1">

                                <button className={`btn ${old === false ? "btn-default" : "btn-info"}  btn-xs`} type="button" id="showOld#button" onClick={buttonChangeHandler}>
                                    <i id="showOld" className="fa fa-check"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <table className="footable table table-stripped toggle-arrow-tiny footable-loaded tablet breakpoint" data-page-size="15">
                                <thead>
                                    <tr>

                                        <th className="footable-visible footable-sortable footable-first-column">Číslo<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable"><span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Datum a čas<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Město<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Název akce<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable">Veřejná/soukromá<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable footable-sortable">Potvrzeno 100%<span className="footable-sort-indicator"></span></th>
                                        <th className="footable-visible footable-sortable footable-sortable">Poznámka<span className="footable-sort-indicator"></span></th>
                                        {userService.isAdmin() && <th className="footable-visible footable-sortable footable-sortable">Akce<span className="footable-sort-indicator"></span></th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {isSaveInProgress && <tr>
                                        <td colSpan="9">
                                            <ProgressSpinner />
                                        </td>
                                    </tr>}
                                    {concerts.length > 0 && concerts.map((oneConcert) => (
                                        <Fragment key={oneConcert.id}>
                                            <ConcertListItem concert={oneConcert} />
                                        </Fragment>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="7" className="footable-visible">
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default ConcertList